import React from 'react'

const BannerGeneric = ({
  title,
  description,
  img,
  children,
}: {
  title: string
  description: string
  img?: string
  children?: React.ReactNode
}) => {
  const styleNumber = Math.floor(Math.random() * 5)
  return (
    <section
      id="banner"
      className={`style${styleNumber}`}
      style={
        img
          ? {
              backgroundImage: `url(${img})`,
            }
          : {}
      }
    >
      <div className="inner">
        <header className="major">
          <h1>{title}</h1>
        </header>
        <div className="content">
          <p>{description}</p>
        </div>
        {children}
      </div>
    </section>
  )
}

export default BannerGeneric

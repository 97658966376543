import React, { useState } from 'react'
import { BB_BASE_URL } from '../utils/constants'
import { IBlogPost } from '../utils/types'
import Comments from './Comments'
import { Seo } from './Seo'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Share from './Share'
import { useBitly } from './hooks'

interface BlogDetailProps {
  data: IBlogPost
}
const BlogDetail: React.FC<BlogDetailProps> = ({ data }) => {
  const [pageUrl] = useState(`${BB_BASE_URL}/blog/${data.slug}`)
  const [bitlink] = useBitly(pageUrl)
  return (
    <>
      <Seo
        title={data.title}
        socialImage={`https:${data.heroImage.file.url as string}`}
        description={data?.description.description}
        keywords={`${data.title}, ${data?.tags.join(', ')}`}
        metaUrl={pageUrl}
      />
      <section className="blogDetails">
        <div className="inner">
          <section>
            <div className="mediaHeader">
              <div className="authorHeader">
                <span className="image">
                  <Link to={`/profile/${data.author.slug}`}>
                    <GatsbyImage
                      image={getImage(data.author.image.localFile)}
                      className="avatarImage"
                      alt={data.author.name}
                    />
                  </Link>
                </span>
                <Link to={`/profile/${data.author.slug}`}>
                  <h5 className="frontmatter">
                    <span>{data.author.name}&nbsp;&nbsp;|</span>
                    <span>{data.updatedAt}</span>
                  </h5>
                </Link>
              </div>

              <Share url={bitlink} />
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: data?.body.childMarkdownRemark.html,
              }}
            ></div>
            <Comments title={data.title as string} slug={`blog/${data.slug}`} />
          </section>
        </div>
      </section>
    </>
  )
}

export default BlogDetail

import React from 'react'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share'

interface ShareProps {
  url: string
}

const Share: React.FC<ShareProps> = ({ url }) => {
  return (
    <div className="bloomB-share">
      <ul className="icons">
        <li>
          <WhatsappShareButton url={url}>
            <span className="icon fa-whatsapp fa-2x">
              <span className="label">Whatsapp</span>
            </span>
          </WhatsappShareButton>
        </li>
        <li>
          <FacebookShareButton url={url}>
            <span className="icon fa-facebook fa-2x">
              <span className="label">Facebook</span>
            </span>
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url={url}>
            <span className="icon fa-twitter fa-2x">
              <span className="label">Twitter</span>
            </span>
          </TwitterShareButton>
        </li>
        <li>
          <EmailShareButton url={url}>
            <span className="icon fa-envelope fa-2x">
              <span className="label">Email</span>
            </span>
          </EmailShareButton>
        </li>
      </ul>
    </div>
  )
}

export default Share

import React from 'react'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import { BB_BASE_URL } from '../utils/constants'

interface CommentsProps {
  slug: string
  title: string
}

const Comments: React.FC<CommentsProps> = ({ slug, title }) => {
  let disqusConfig = {
    url: `${BB_BASE_URL}/${slug}`,
    identifier: slug,
    title: title,
  }
  return (
    <>
      <CommentCount config={disqusConfig} placeholder={'...'} />
      <Disqus config={disqusConfig} />
    </>
  )
}

export default Comments

import { graphql } from 'gatsby'
import React from 'react'
import BannerGeneric from '../components/BannerGeneric'
import BlogDetail from '../components/BlogDetail'
import { useSlack } from '../components/hooks'
import Layout from '../components/layout'

const profile = ({
  data: { contentfulBlogPost: blogPost },
}: {
  data: { contentfulBlogPost: any }
}) => {
  useSlack(`Journal titled ${blogPost.title}`)
  console.log('BLOG', blogPost)
  return (
    <Layout>
      <div className="inner">
        <BannerGeneric
          title={blogPost.title as string}
          description={blogPost.description.description}
          img={`https://${blogPost.heroImage.file.url}`}
        />
        <BlogDetail data={blogPost} />
      </div>
    </Layout>
  )
}

export default profile

export const memberQuery = graphql`
  query blogBySlug($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      description {
        description
      }
      heroImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      heroImage {
        file {
          url
        }
      }
      author {
        name
        slug
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 75
                height: 75
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        contentful_id
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      updatedAt(formatString: "MMMM DD, YYYY")
      tags
    }
  }
`
